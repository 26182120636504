import useHttp from "@fast-ninjas/hooks.use-http";
import { Fare, PickupRequest, Profile } from "../types";
import { useCallback } from "react";
import awsConfig from "../awsConfig";

export default function useApi() {
  const { loading, post } = useHttp();

  const requestPickup = useCallback(
    async (account: Profile, req: PickupRequest) => {
      const url = `${awsConfig.apiEndpoint}/api/pickups/${account.country}/${account.city}/${account.id}`;
      const response = await post(url, req);
      if (response.ok) {
        const pickup = (await response.json()) as PickupRequest;
        return pickup;
      }
      throw new Error("Error requesting pickup");
    },
    [post]
  );

  const confirmOTP = useCallback(
    async (otp: string, paymentId: string, externalPaymentId: string) => {
      const response = await post(
        `${awsConfig.apiEndpoint}/api/payments/${paymentId}/confirm-otp`,
        { otp, externalPaymentId }
      );
      if (!response.ok) throw response.statusText;
      return true;
    },
    [post]
  );

  const calculateFare = useCallback(
    async (account: Profile, req: PickupRequest) => {
      const url = `${awsConfig.apiEndpoint}/api/pickups/${account.country}/${account.city}/${account.id}/fare`;
      const response = await post(url, req);
      if (!response.ok) {
        throw new Error("Error calculating fare");
      }
      return (await response.json()) as Fare;
    },
    [post]
  );

  return { loading, requestPickup, calculateFare, confirmOTP };
}
