import { Backpack } from "@mui/icons-material";
import {
  Alert,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { PackageType, Profile } from "../types";

type PackageDetailsProps = {
  requestType: string;
  serviceType?: string;
  description?: string;
  amount?: number;
  account?: Profile;
  onChange?: (data: {
    type?: PackageType;
    description?: string;
    amount?: number;
    numPassengers?: number;
  }) => void;
};
export default function PackageDetails(props: PackageDetailsProps) {
  const [serviceTypeCode, setServiceTypeCode] = useState<string | undefined>(
    props.serviceType ?? "food"
  );
  const { account, onChange } = props;
  const [description, setDescription] = useState(props.description ?? "");
  const [amount, setAmount] = useState(props.amount ?? "0.0");
  const [numPassengers, setNumPassengers] = useState(1);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const serviceType = account?.deliveryConfig?.serviceTypes?.find(
    (pt) => pt.code === serviceTypeCode
  );

  const requestType = props.requestType;

  const nextButtonClicked = useCallback(() => {
    if (requestType === "delivery") {
      if (!serviceType) {
        setErrorMessage("Please select a service type");
        return;
      }
      if (serviceType.isPurchase && !amount) {
        setErrorMessage("Please enter the amount of the purchase");
        return;
      }
    }
    setErrorMessage(undefined);

    if (onChange) {
      onChange({
        type: serviceType,
        description,
        amount: amount ? Number(amount) : undefined,
        numPassengers: numPassengers,
      });
    }
  }, [
    serviceType,
    description,
    amount,
    requestType,
    numPassengers,
    onChange,
    setErrorMessage,
  ]);

  const DetailFormContent = useMemo(() => {
    if (requestType === "taxi") {
      return (
        <>
          <FormControl fullWidth size="small" style={{ marginTop: "20px" }}>
            <InputLabel id="number-f-passengers">
              Number of passengers
            </InputLabel>
            <Select
              label="Number of passengers"
              fullWidth
              size="small"
              labelId="number-of-passengers"
              value={numPassengers}
              onChange={(e) => setNumPassengers(Number(e.target.value))}
            >
              {[1, 2, 3, 4].map((n) => (
                <MenuItem key={n} value={n}>
                  {n} Passenger{n > 1 ? "s" : ""}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      );
    } else {
      console.log("serviceTypeCode", account?.deliveryConfig?.serviceTypes);
      return (
        <>
          <FormControl fullWidth size="small" style={{ marginTop: "20px" }}>
            <InputLabel id="package-type">Type of package</InputLabel>
            <Select
              label="Type of service"
              fullWidth
              size="small"
              labelId="package-type"
              value={serviceTypeCode}
              onChange={(e) => setServiceTypeCode(e.target.value)}
            >
              {account?.deliveryConfig?.serviceTypes?.map((type) => (
                <MenuItem key={type.code} value={type.code}>
                  {type.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" style={{ marginTop: "20px" }}>
            <TextField
              label="Instructions"
              placeholder="Instructions for the delivery person. (Optional)"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              size="small"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Backpack />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          {serviceType && serviceType.isPurchase && (
            <FormControl fullWidth size="small" style={{ marginTop: "20px" }}>
              <TextField
                label="Amount"
                placeholder="Total amount of purchase"
                variant="outlined"
                fullWidth
                size="small"
                inputMode="decimal"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="caption">GHS</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          )}
        </>
      );
    }
  }, [
    account,
    serviceType,
    serviceTypeCode,
    description,
    amount,
    numPassengers,
    requestType,
  ]);

  return (
    <Stack width="100%">
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {DetailFormContent}
      <Button
        variant="contained"
        fullWidth
        size="large"
        style={{ marginTop: "20px" }}
        onClick={nextButtonClicked}
      >
        Next
      </Button>
    </Stack>
  );
}
