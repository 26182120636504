import {
  Alert,
  Box,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Place, PackageType, Profile, Fare } from "../types";
import { LocationOn, PhoneAndroidOutlined } from "@mui/icons-material";
import { useCallback, useState } from "react";
import Currency from "../shared/currency";

type ServiceRequestProps = {
  requestType: string;
  pickupLocation?: Place;
  dropoffLocation?: Place;
  serviceType?: PackageType;
  description?: string;
  amount: number;
  account?: Profile;
  provider?: string;
  phoneNumber?: string;
  loading?: boolean;
  bill?: Fare;
  onConfirm?: (paymentMethod: string, accountNumber: string) => void;
};

const BillLine = styled(Stack)(({ theme }) => ({
  width: "100%",
  justifyContent: "space-between",
  borderRadius: 4,
  marginBottom: theme.spacing(1),
}));

const BillBreakdownText = styled(Typography)(({ theme }) => ({
  fontSize: 11,
}));

export function ReviewAndPay(props: ServiceRequestProps) {
  const { loading, requestType, bill, onConfirm } = props;
  const [provider, setProvider] = useState(props.provider ?? "");
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber ?? "");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const isTaxi = requestType === "taxi";
  const requstButtonLabel = loading ? "Sending request..." : "Send request";

  const requestButtonClicked = useCallback(() => {
    let errorMessage = "";
    if (!provider) {
      errorMessage = "Please select a payment provider";
    } else if (!phoneNumber) {
      errorMessage = "Please enter your phone number";
    }
    if (errorMessage) {
      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage(undefined);
    onConfirm && onConfirm(provider, phoneNumber);
  }, [onConfirm, provider, phoneNumber, setErrorMessage]);

  return (
    <Stack width="100%">
      <Typography variant="h6">Review and pay</Typography>

      <Paper style={{ padding: 12, backgroundColor: "#ededee" }} elevation={0}>
        <Typography variant="body1" pt={2}>
          <strong>Service:</strong>{" "}
          {isTaxi ? "Taxi request" : props.serviceType?.displayName}
        </Typography>
        <Typography variant="body1">
          <LocationOn />
          <strong>Pickup:</strong> {props.pickupLocation?.name}
        </Typography>
        <Typography variant="body1">
          <LocationOn />
          <strong>Drop off:</strong> {props.dropoffLocation?.name}
          <span style={{ color: "grey", fontSize: 12, marginLeft: 8 }}>
            {bill?.distance}
            {bill?.metric}
          </span>
        </Typography>
        {!isTaxi && (
          <Typography variant="body1">
            <strong>Description:</strong>{" "}
            <span style={{ color: "grey", fontSize: 12 }}>
              {props.description}
            </span>
          </Typography>
        )}
      </Paper>

      {bill && (
        <Paper elevation={0} style={{ padding: 4 }}>
          {bill.details.purchaseAmount > 0 && (
            <BillLine direction="row" spacing={2}>
              <BillBreakdownText>Purchase amount</BillBreakdownText>
              <Currency amount={bill.details.purchaseAmount} variant="small" />
            </BillLine>
          )}
          <BillLine direction="row" spacing={2}>
            <BillBreakdownText>Charge</BillBreakdownText>
            <Currency amount={bill.details.charge} variant="small" />
          </BillLine>
          <BillLine direction="row" spacing={2}>
            <BillBreakdownText>Service fee </BillBreakdownText>
            <Currency amount={bill.details.serviceFee} variant="small" />
          </BillLine>
          <BillLine direction="row" spacing={2}>
            <Typography>
              <strong>Total</strong>
            </Typography>
            <Currency amount={bill.total} variant="large" />
          </BillLine>
        </Paper>
      )}

      <Typography pt={1}>
        <strong>Payment method</strong>
      </Typography>
      <Stack direction="row" justifyContent="center">
        <ToggleButtonGroup
          color="primary"
          value={provider}
          exclusive
          onChange={(_, v) => setProvider(v)}
          aria-label="Platform"
          disabled={loading}
          fullWidth
        >
          <ToggleButton value="mtn">MTN MoMo</ToggleButton>
          <ToggleButton value="voda">Voda Cash</ToggleButton>
          <ToggleButton value="airtel">Airtel-Tigo</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="phoneNumber"
        label={phoneNumber ? "Phone Number" : undefined}
        name="phoneNumber"
        autoComplete="off"
        value={phoneNumber}
        size="medium"
        inputMode="tel"
        type="tel"
        placeholder="Phone number"
        disabled={loading}
        onChange={(e) => setPhoneNumber(e.currentTarget.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneAndroidOutlined />
            </InputAdornment>
          ),
        }}
      />
      {errorMessage && (
        <Box p={1}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}
      <LoadingButton
        onClick={requestButtonClicked}
        loading={loading}
        variant="contained"
      >
        {requstButtonLabel}
      </LoadingButton>
    </Stack>
  );
}
