import { useCallback, useEffect, useState } from "react";
import { Profile } from "../types";
import useHttp from "@fast-ninjas/hooks.use-http";
import awsConfig from "../awsConfig";

export function useProfile() {
  const { loading, get } = useHttp();
  const [account, setAccount] = useState<Profile>();

  const loadClientProfile = useCallback(async () => {
    const url = `${awsConfig.apiEndpoint}/api/customer/public/profile`;
    try {
      const data = await get(url, {}, 60); // 60 seconds
      setAccount(data);
    } catch (e) {
      console.log(e);
    }
  }, [get]);

  useEffect(() => {
    loadClientProfile();
  }, [loadClientProfile]);

  return { account, loading };
}
