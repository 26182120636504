import { useCallback, useEffect, useState } from "react";

export function useLocalStore<T>(initialState: T, key: string) {
  const [data, setState] = useState<T>(initialState);
  const save = useCallback(
    (newState: T) => {
      setState(newState);
      localStorage.setItem(key, JSON.stringify(newState));
    },
    [setState, key]
  );

  useEffect(() => {
    const saved = localStorage.getItem(key);
    if (saved) {
      setState(JSON.parse(saved));
    }
  }, [setState, key]);

  return [data, save] as const;
}
