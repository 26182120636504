import { PickupRequest } from "../types";
import { useLocalStore } from "./useLocalStore";

export function useHistory(maxLength = 5) {
  const [history, save] = useLocalStore<PickupRequest[]>([], "history");
  const add = (req: PickupRequest) => {
    const newHistory = [req, ...history];
    save(newHistory.slice(0, maxLength));
  };

  return { history, add };
}
