import {
  Avatar,
  Divider,
  InputAdornment,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { Add, HouseOutlined, LocationOn, Map, Room } from "@mui/icons-material";
import { Place } from "../types";
import { useCallback, useMemo, useState } from "react";
import { BottomSheet } from "../shared/bottomSheet";

const PlaceType = {
  house: "House",
  landmark: "Landmark",
};

const AddressEntryStep = {
  enterHouseNumber: "EnterHouseNumber",
  selectLandmark: "SelectLandmark",
};

type AddressInputProps = {
  country: string;
  city: string;
  label?: string;
  open?: boolean;
  places?: Place[];
  value?: Place;
  onChange?: (place: Place) => void;
  onClose?: () => void;
  closeSheet?: () => void;
};

export function AddressInput(props: AddressInputProps) {
  const { label, open, places, city, country, onChange, onClose, closeSheet } =
    props;
  const [search, setSearch] = useState("");
  const [step, setStep] = useState(AddressEntryStep.enterHouseNumber);

  const handleHouseNumberSelected = useCallback(
    (place: Place) => {
      onChange?.(place);
    },
    [onChange]
  );

  const handleLandmarkSelected = useCallback(
    (landmark: Place) => {
      handleHouseNumberSelected({
        id: "",
        lat: 0.0,
        lng: 0.0,
        name: search,
        city: city,
        country: country,
        type: PlaceType.house,
        landmark,
      });
    },
    [handleHouseNumberSelected, search, city, country]
  );

  const handleCreateNewHouseNumber = useCallback(
    (houseNumber: string) => {
      setSearch(houseNumber);
      setStep(AddressEntryStep.selectLandmark);
    },
    [setStep]
  );

  const handleCurrentLocationSelected = useCallback(
    (houseNumber: string) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          handleHouseNumberSelected({
            id: "",
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            name: houseNumber,
            city: city,
            country: country,
            type: PlaceType.house,
          });
        });
      } else {
        // TODO: show error message
        console.log("Geolocation is not supported by this browser.");
      }
    },
    [city, country, handleHouseNumberSelected]
  );
  return (
    <BottomSheet open={open} onClose={onClose} closeSheet={closeSheet}>
      {step === AddressEntryStep.enterHouseNumber && (
        <PlaceSelector
          label={label}
          placeholder="Place/House number"
          places={places}
          city={city}
          value={props.value}
          placeType={PlaceType.house}
          icon={<HouseOutlined />}
          canCreate={true}
          // TODO: Enable current location selection
          // canUseCurrentLocation={true}
          onSelect={handleHouseNumberSelected}
          onCreate={handleCreateNewHouseNumber}
          onUseCurrentLocation={handleCurrentLocationSelected}
        />
      )}

      {step === AddressEntryStep.selectLandmark && (
        <Stack width="100%" spacing={2}>
          <ReadOnlyHouseNumber houseNumber={search} />
          <PlaceSelector
            label="Select landmark or place nearby this address"
            placeholder={`Any popular place near ${search}`}
            places={places}
            city={city}
            value={props.value}
            placeType={PlaceType.landmark}
            onSelect={handleLandmarkSelected}
          />
        </Stack>
      )}
    </BottomSheet>
  );
}

type PlaceSelectorProps = {
  label?: string;
  placeholder?: string;
  places?: Place[];
  placeType?: string;
  canCreate?: boolean;
  value?: Place;
  city?: string;
  icon?: JSX.Element;
  canUseCurrentLocation?: boolean;
  onSelect?: (place: Place) => void;
  onCreate?: (placeName: string) => void;
  onUseCurrentLocation?: (placeName: string) => void;
};

function PlaceSelector(props: PlaceSelectorProps) {
  const {
    places,
    placeType,
    label,
    city,
    value,
    placeholder,
    canCreate,
    canUseCurrentLocation,
    icon,
    onSelect,
    onCreate,
    onUseCurrentLocation,
  } = props;

  const [search, setSearch] = useState("");

  const filteredPlaces = useMemo(
    () =>
      places?.filter(
        (place) =>
          place.type === placeType &&
          (place.name
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
            place.area
              ?.toLocaleLowerCase()
              .includes(search.toLocaleLowerCase()))
      ),
    [places, search, placeType]
  );

  const handleSelect = useCallback(
    (place: Place) => {
      onSelect?.(place);
    },
    [onSelect]
  );

  const handleCurrentLocation = useCallback(() => {
    onUseCurrentLocation?.(search);
  }, [search, onUseCurrentLocation]);

  return (
    <Stack width="100%">
      <TextField
        label={label ?? "Enter address"}
        placeholder={placeholder ?? "Landmark, place, street, etc."}
        variant="outlined"
        fullWidth
        size="small"
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {icon ? icon : <LocationOn />}
            </InputAdornment>
          ),
        }}
      />
      <List style={{ width: "100%" }}>
        {canUseCurrentLocation && (
          <ListItemButton onClick={handleCurrentLocation}>
            <ListItemAvatar>
              <Avatar>
                <Room />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Use my current location"
              secondary={city ?? "Sunyani"}
            />
          </ListItemButton>
        )}
        <Divider />
        {filteredPlaces?.map((place) => (
          <ListItemButton
            key={place.id}
            onClick={() => handleSelect(place)}
            selected={value?.id === place.id}
          >
            <ListItemAvatar>
              <Avatar>
                <Map />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={place.name}
              secondary={`${place.city} ${place.country}`}
            />
          </ListItemButton>
        ))}
        {canCreate && !filteredPlaces?.length && search?.length > 3 ? (
          <ListItemButton onClick={() => onCreate?.(search)}>
            <ListItemAvatar>
              <Avatar>
                <Add />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`Create ${search}`}
              secondary={city ?? `Sunyani`}
            />
          </ListItemButton>
        ) : null}
      </List>
    </Stack>
  );
}

function ReadOnlyHouseNumber(props: { houseNumber: string }) {
  return (
    <TextField
      label="Building/House number"
      variant="outlined"
      fullWidth
      size="small"
      aria-readonly={true}
      value={props.houseNumber}
      disabled={true}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <HouseOutlined />
          </InputAdornment>
        ),
      }}
    />
  );
}
