import { Close } from "@mui/icons-material";
import { Box, Drawer, IconButton, styled } from "@mui/material";
import { useCallback } from "react";

type BottomSheetProps = {
  open?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  closeSheet?: () => void;
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: calc(70vh - 64px);
  border-radius: 10px 10px 0px 0px;
  padding: 20px;
  opacity: 0.9;
`;

export function BottomSheet(props: BottomSheetProps) {
  const { open, children, onClose, closeSheet } = props;

  const handleClose = useCallback(() => {
    onClose?.();
    closeSheet?.();
  }, [closeSheet, onClose]);

  return (
    <Drawer open={open ?? false} anchor="bottom">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        {closeSheet && (
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        )}
      </Box>
      <Container>{children}</Container>
    </Drawer>
  );
}
