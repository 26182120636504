import {
  Badge,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { PickupRequest } from "../types";

type historyCardProps = {
  request: PickupRequest;
};

const HistoryCard = ({ request }: historyCardProps) => {
  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        <ListItem>
          <Stack
            flexDirection={"row"}
            sx={{
              width: "100%",
              pr: 2,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ListItemText
              primary={`${request.pickup.name} - ${request.dropoff.name}`}
              secondary={
                request.timestamp
                  ? new Date(request.timestamp).toLocaleDateString()
                  : ""
              }
            />
            <Badge color="primary" badgeContent={request.status} />
          </Stack>
        </ListItem>
        <Divider variant="fullWidth" component="li" />
      </List>
    </>
  );
};

export default HistoryCard;
