import { Box, Button, Fab, Stack, Typography, styled } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AddressInput } from "./adressInput";
import { useProfile } from "../hooks/useProfile";
import { Fare, PackageType, PickupRequest, Place } from "../types";
import { BottomSheet } from "../shared/bottomSheet";
import PackageDetails from "./packageDetail";
import { ReviewAndPay } from "./reviewAndPay";
import { StatusCard } from "./statusCard";
import useApi from "../hooks/useApi";
import { DriveEtaSharp } from "@mui/icons-material";
import HistoryCard from "../components/HistoryCard";
import { useHistory } from "../hooks/useHistory";
import { OtpForm } from "../components/otpForm";

const confirmOtpStatus = "Awaiting OTP";
const FormSteps = {
  initial: "initial",
  pickupLocation: "origin",
  dropOffLocation: "destination",
  packageDetails: "load",
  confirmation: "confirmation",
  otpForm: "otp",
  success: "success",
  failure: "failure",
};

const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 64px);
`;

export function HomePage() {
  const { account } = useProfile();
  const [requestType, setRequestType] = useState("");
  const [step, setStep] = useState(FormSteps.initial);
  const [pickupLocation, setPickupLocation] = useState<Place>();
  const [dropoffLocation, setDropoffLocation] = useState<Place>();
  const [serviceType, setServiceType] = useState<PackageType>();
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const [provider, setProvider] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [numPassengers, setNumPassengers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [bill, setBill] = useState<Fare | undefined>(undefined);
  const { calculateFare, requestPickup, confirmOTP } = useApi();
  const [openHistorySheet, setOpenHistorySheet] = useState(false);
  const [tempPikcup, setTempPickup] = useState<PickupRequest>();
  const { history, add } = useHistory();

  const request = useMemo(() => {
    if (!pickupLocation || !dropoffLocation) {
      return undefined;
    }

    return {
      pickup: pickupLocation,
      dropoff: dropoffLocation,
      request: {
        description,
        purchaseAmount: amount,
        serviceType: serviceType?.code,
        numPassengers,
        phoneNumber,
        requestType: requestType,
        time: new Date().toISOString(),
      },
      payment: {
        method: provider,
        phoneNumber,
      },
    } as PickupRequest;
  }, [
    pickupLocation,
    dropoffLocation,
    description,
    amount,
    serviceType,
    numPassengers,
    phoneNumber,
    provider,
    requestType,
  ]);
  useEffect(() => {
    if (!request || !pickupLocation || !dropoffLocation) {
      return undefined;
    }
    if (account) {
      calculateFare(account, request)
        .then(setBill)
        .catch((err) => {
          console.log(err);
        });
    }
  }, [request, pickupLocation, dropoffLocation, account, calculateFare]);

  const handlePickAddressSelected = (place: Place) => {
    setPickupLocation(place);
    setStep(FormSteps.dropOffLocation);
  };
  const handleDropAddressSelected = (place: Place) => {
    setDropoffLocation(place);
    setStep(FormSteps.packageDetails);
  };

  const handleCloseSheet = () => {
    setStep(FormSteps.initial);
  };

  const startRequest = useCallback(
    (serviceType: string) => {
      setRequestType(serviceType);
      setStep(FormSteps.pickupLocation);
    },
    [setRequestType, setStep]
  );

  const handlePackageDetailsChanged = useCallback(
    (d: {
      type?: PackageType;
      description?: string;
      amount?: number;
      numPassengers?: number;
    }) => {
      const { type, description, amount, numPassengers } = d;
      setServiceType(type);
      setDescription(description ?? "");
      setAmount(amount ?? 0);
      setNumPassengers(numPassengers ?? 0);
      setStep(FormSteps.confirmation);
    },
    [setServiceType, setDescription, setAmount, setStep]
  );

  const handleSendRequest = useCallback(
    async (provider: string, phoneNumber: string) => {
      setIsLoading(true);
      setProvider(provider);
      setPhoneNumber(phoneNumber);

      // Very unlikely to happen but just in case
      if (!request || !account) {
        setIsLoading(false);
        return;
      }

      // Update the request with the payment method and phone number
      const finalRequest = {
        ...request,
        payment: { method: provider, phoneNumber },
        request: { ...request.request, phoneNumber, bill },
      };

      try {
        const response = await requestPickup(account, finalRequest);
        const pickup = response as PickupRequest;
        add(pickup);
        if (response.request.payStatus === confirmOtpStatus) {
          setIsLoading(false);
          setStep(FormSteps.otpForm);
          setTempPickup(pickup);
          return;
        }
        setStep(FormSteps.success);
      } catch (err) {
        setIsLoading(false);
        setStep(FormSteps.failure);
        console.log(err);
      }
    },
    [
      request,
      account,
      bill,
      add,
      setProvider,
      setPhoneNumber,
      setStep,
      requestPickup,
      setIsLoading,
    ]
  );

  const handleConfirmOtp = useCallback(
    async (otp: string) => {
      if (!tempPikcup || !tempPikcup.id || !tempPikcup.request.payId) {
        return;
      }
      await confirmOTP(otp, tempPikcup.id, tempPikcup.request.payId);
      setStep(FormSteps.success);
    },
    [confirmOTP, setStep, tempPikcup]
  );

  const handleOpenHistorySheet = () => setOpenHistorySheet(true);
  const handleCloseHistorySheet = () => setOpenHistorySheet(false);
  return (
    <PageContainer>
      <img
        src={account?.logo ?? "/logo.jpg"}
        alt="logo"
        width="300"
        height="300"
      />
      {account?.taxiConfig?.enabled && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => startRequest("taxi")}
          style={{ marginBottom: 20, width: 260 }}
        >
          {account?.taxiConfig.requestPrompt ?? "Request Taxi"}
        </Button>
      )}

      {account?.deliveryConfig?.enabled && (
        <Button
          variant={account?.taxiConfig?.enabled ? "outlined" : "contained"}
          onClick={() => startRequest("delivery")}
          color={account?.taxiConfig?.enabled ? "inherit" : "primary"}
          style={{ marginBottom: 20, width: 260 }}
        >
          {account.deliveryConfig?.requestPrompt ?? "Request Delivery"}
        </Button>
      )}

      <AddressInput
        open={step === FormSteps.pickupLocation}
        label="Pickup address"
        places={account?.places}
        value={pickupLocation}
        city={account?.city ?? "Sunyani"}
        country={account?.country ?? "Ghana"}
        onChange={handlePickAddressSelected}
        closeSheet={handleCloseSheet}
      />

      <AddressInput
        open={step === FormSteps.dropOffLocation}
        label="Drop off address"
        places={account?.places}
        value={dropoffLocation}
        city={account?.city ?? "Sunyani"}
        country={account?.country ?? "Ghana"}
        onChange={handleDropAddressSelected}
        closeSheet={handleCloseSheet}
      />

      <BottomSheet
        open={step === FormSteps.packageDetails}
        closeSheet={handleCloseSheet}
      >
        <PackageDetails
          requestType={requestType}
          account={account}
          onChange={handlePackageDetailsChanged}
          amount={amount}
          description={description}
          serviceType={serviceType?.code}
        />
      </BottomSheet>

      <BottomSheet
        open={step === FormSteps.confirmation}
        closeSheet={handleCloseSheet}
      >
        <ReviewAndPay
          account={account}
          amount={amount}
          description={description}
          dropoffLocation={dropoffLocation}
          pickupLocation={pickupLocation}
          serviceType={serviceType}
          requestType={requestType}
          provider={provider}
          bill={bill}
          phoneNumber={phoneNumber}
          onConfirm={handleSendRequest}
          loading={isLoading}
        />
      </BottomSheet>
      <BottomSheet
        open={step === FormSteps.otpForm}
        closeSheet={handleCloseSheet}
      >
        <OtpForm processing={isLoading} onSubmit={handleConfirmOtp} />
      </BottomSheet>

      <BottomSheet
        open={step === FormSteps.success}
        closeSheet={handleCloseSheet}
      >
        <StatusCard
          severity="success"
          message="Request sent successfully!"
          detail={
            <Typography color="grey" textAlign="center">
              Please complete the payment on your phone to complete the request.
              You will be notified when we receive your payment.
            </Typography>
          }
          secondaryAction={{
            text: "Finish",
            onClick: handleCloseSheet,
          }}
        />
      </BottomSheet>
      <BottomSheet open={openHistorySheet} closeSheet={handleCloseHistorySheet}>
        <Stack flexDirection={"column"} sx={{ width: "100%" }}>
          <Typography variant="h6" sx={{ px: 2 }}>
            History
          </Typography>
          {history.map((req) => (
            <HistoryCard key={req.id} request={req} />
          ))}
        </Stack>
      </BottomSheet>
      <Fab>
        <DriveEtaSharp onClick={handleOpenHistorySheet} />
      </Fab>
    </PageContainer>
  );
}
