import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2b2b2b",
    },
    secondary: {
      main: "#f5f5f5",
    },
    background: {
      //   default: "rgb(243, 244, 249)",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        size: "large",
      },
      styleOverrides: {
        root: {
          borderRadius: "6px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "6px",
          ".Mui-focused fieldset": {
            borderColor: "blue",
          },
        },
      },
    },
  },
});
